
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { addApolloState, initializeApollo, Seo_Swnz_SeoFragment, useGetHomepagePageCollectionBySlugQuery, } from 'content-service';
import { connectorElementQueries, footerQuery, headerQuery, heroFeaturedQuery, homepageCardsQuery, homepageHeroQuery, homepageQuery, } from 'content-service/src/helpers';
import { GetStaticProps } from 'next';
import { CONTENT_SERVICE } from 'src/constants';
import { useTrackPageViews } from 'tracking';
import { CallToAction, PageSeo } from 'ui';
import { getQueryLocale } from 'utils';
import { createCtaButton } from 'utils/src/helpers/createCtaButton';
import { buildPageQueryInput } from 'utils/src/helpers/slug';
import { SITE_CONFIG } from '../constants';
import HomePage from '../templates/HomePage';
interface HomeProps {
    preview: boolean;
    locale: string;
    pageSeo: Seo_Swnz_SeoFragment;
}
const Home = ({ preview, locale, pageSeo }: HomeProps) => {
    const { data, error } = useGetHomepagePageCollectionBySlugQuery({
        variables: {
            ...buildPageQueryInput(SITE_CONFIG.SITE_ROOT_PATH),
            preview,
            locale
        }
    });
    const page = data?.swnz_pageCollection?.items[0];
    const pageTheme = page?.pageTheme ?? '';
    const tags = page?.content?.__typename === 'SWNZ_PageHomepage' ? page?.content?.tags : undefined;
    useTrackPageViews({ pageName: pageSeo?.title ?? 'Homepage', pageViewData: tags });
    if (error ||
        !page ||
        !data ||
        !data.swnz_globalSettingsCollection ||
        data?.swnz_pageCollection === null) {
        throw new Error('Error');
    }
    const settings = data.swnz_globalSettingsCollection?.items[0];
    const homepageCtas: CallToAction[] = [];
    if (settings?.codeId === pageTheme && settings?.callToActionCollection?.items?.length) {
        settings.callToActionCollection.items.forEach((cta) => {
            const ctaButton = createCtaButton(cta);
            if (ctaButton === undefined)
                return;
            homepageCtas.push(ctaButton);
        });
    }
    return (<>
      <PageSeo pageSeo={pageSeo}/>
      <HomePage page={page} homepageCtas={homepageCtas} pageTheme={pageTheme}/>
    </>);
};
export default Home;
const getStaticProps: GetStaticProps = async ({ preview = false, locale, defaultLocale, }) => {
    const apolloClient = initializeApollo({ preview });
    const queryLocale = getQueryLocale(locale, defaultLocale);
    let pageSeo: Seo_Swnz_SeoFragment | undefined = undefined;
    let showLcf = true;
    try {
        const [homepageQueryResponse, ...rest] = await Promise.allSettled([
            homepageQuery({ apolloClient, preview, locale: queryLocale }),
            homepageHeroQuery({ apolloClient, preview, locale: queryLocale }),
            headerQuery({
                apolloClient,
                preview,
                codeId: CONTENT_SERVICE.HEADER_CODE_ID,
                locale: queryLocale
            }),
            homepageCardsQuery({ apolloClient, preview, locale: queryLocale }),
            footerQuery({ apolloClient, preview, locale: queryLocale }),
        ]);
        rest.filter((queryResponse) => {
            if (queryResponse.status !== 'fulfilled')
                console.error(`⚠️  Error in query response for home page`);
        });
        if (homepageQueryResponse.status === 'fulfilled') {
            if (homepageQueryResponse?.value?.pageSeo) {
                pageSeo = homepageQueryResponse.value.pageSeo;
            }
            else {
                console.error(`🚨 Error in pageSeo response for home page`);
            }
            if (homepageQueryResponse?.value?.showLcf === false) {
                showLcf = false;
            }
            try {
                if (homepageQueryResponse?.value?.hero) {
                    await heroFeaturedQuery({
                        apolloClient,
                        id: homepageQueryResponse.value.hero?.sys.id,
                        locale: queryLocale,
                        preview
                    });
                }
            }
            catch (error) {
                console.error(`🚨 Error in homepageQueryResponse response for the homepage`, error);
                return { notFound: true };
            }
            if (homepageQueryResponse?.value?.elementData?.length) {
                await connectorElementQueries(apolloClient, homepageQueryResponse.value.elementData, queryLocale, preview);
            }
            else {
                console.error(`🚨 Error in homepageQueryResponse response for homepage`);
                return { notFound: true };
            }
        }
        return addApolloState(apolloClient, {
            props: { preview, locale: queryLocale, pageSeo: pageSeo ?? null, showLcf }
        });
    }
    catch (error) {
        console.error(`  Error executing queries in home page`);
        return {
            props: { preview }
        };
    }
};

    async function __Next_Translate__getStaticProps__191e9d7d54d__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__191e9d7d54d__ as getStaticProps }
  