import { Box } from '@chakra-ui/react'
import { HomepageHero_Swnz_PageHomepageFragment, Page_Swnz_PageFragment } from 'content-service'
import dynamic from 'next/dynamic'
import type { ReactElement } from 'react'
import { CallToAction, Connector, HeroFeaturedConnect, RegionCardsCarouselConnect } from 'ui'
import { SectionCollectionId } from 'utils'
import {
  HomepageHeroConnect,
  HomepagePanelConnect,
  HomepageStoryPanelConnect,
} from '../../connectors'

export interface HomePageProps {
  page: Page_Swnz_PageFragment
  pageTheme?: string
  homepageCtas: CallToAction[] | []
}

interface ComponentMapArgs extends Connector {
  key: number
}

interface ComponentMap {
  [typename: string]: ({ id, typename, key }: ComponentMapArgs) => ReactElement
}

interface HomePageHeroSelect {
  contentTypename: string
  contentId: string
  homepageCtas: CallToAction[]
  pageTheme: string
  hero?: HomepageHero_Swnz_PageHomepageFragment['hero']
}

function HomePageHeroSelect({
  contentTypename,
  contentId,
  homepageCtas,
  pageTheme,
  hero,
}: HomePageHeroSelect) {
  if (hero) {
    return <HeroFeaturedConnect typename={hero?.__typename ?? ''} id={hero?.sys.id ?? ''} />
  }

  return (
    <HomepageHeroConnect
      typename={contentTypename}
      id={contentId}
      homepageHeroCtas={homepageCtas}
      pageTheme={pageTheme}
    />
  )
}

const StoryModal = dynamic(() => import('ui/src/components/StoryModal/StoryModal'))

const HomePage = ({ page, homepageCtas, pageTheme }: HomePageProps) => {
  const content = page.content
  const contentTypename = content?.__typename
  const contentId = content?.sys.id

  if (
    !content ||
    !(content?.__typename === 'SWNZ_PageHomepage') ||
    !('sectionsCollection' in content) ||
    !content?.sectionsCollection?.items ||
    !contentTypename ||
    !contentId
  ) {
    return null
  }

  const componentMap: ComponentMap = Object.freeze({
    SWNZ_HomepagePanel: function renderComponent({ key, ...props }) {
      return <HomepagePanelConnect key={key} {...props} />
    },
    SWNZ_HomepageStoryPanel: function renderComponent({ key, ...props }) {
      return <HomepageStoryPanelConnect key={key} {...props} />
    },
    SWNZ_CardContainer: function renderComponent({ key, ...props }) {
      return <RegionCardsCarouselConnect key={key} {...props} />
    },
  })

  return (
    <Box as="main">
      <HomePageHeroSelect
        contentTypename={contentTypename}
        contentId={contentId}
        homepageCtas={homepageCtas}
        pageTheme={pageTheme ?? ''}
        hero={content.hero}
      />
      {content.sectionsCollection.items.map((item: SectionCollectionId, index: number) => {
        if (item?.__typename && item.sys.id) {
          return componentMap[item.__typename]({
            id: item.sys.id,
            typename: item.__typename,
            key: index,
          })
        }
      })}
      <StoryModal />
    </Box>
  )
}

export default HomePage
